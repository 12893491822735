import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import * as S from './SizeSelector.styles';
import FunnelContext from '../../context/FunnelContext';

const SizeSelector = props => {
  const { title = 'Select Your Dog’s Size', sizes = [] } = props;

  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selecting, setSelecting] = useState(false);
  const containerRef = useRef(null);

  const {
    dogSize,
    setDogSize,
    setCurrentTubIndex: setCurrentTubIndexContext,
    currentCategory,
    setCurrentProduct
  } = useContext(FunnelContext);

  const initSelected = useCallback(
    id => {
      setSelected(id);
      setDogSize(id);
    },
    [setSelected, setDogSize]
  );

  useEffect(() => {
    // close contain on hover out
    const currentContainerRef = containerRef.current;
    const mouseOutHandler = evt => {
      setSelecting(false);
    };
    currentContainerRef.addEventListener('mouseleave', mouseOutHandler);

    const setSelectedSize = () => {
      if (dogSize) {
        initSelected(dogSize);
        return;
      }
      if (!selected) {
        const {
          id,
          setCurrentTubIndex = false,
          currentTubIndex = 0
        } = sizes.find(size => size.defaultSize);
        initSelected(id);
        if (setCurrentTubIndex) {
          setCurrentTubIndexContext(currentTubIndex);
          setCurrentProduct(
            currentCategory === `subscriptions`
              ? `subscription_${currentTubIndex}`
              : `onetime_${currentTubIndex}`
          );
        }
      }
    };
    if (!init) {
      setSelectedSize();
    }
    return () => {
      currentContainerRef.removeEventListener('mouseleave', mouseOutHandler);
    };
  }, [
    sizes,
    selected,
    setSelected,
    init,
    setInit,
    containerRef,
    initSelected,
    dogSize,
    setCurrentTubIndexContext,
    currentCategory,
    setCurrentProduct
  ]);

  const handleSelecting = () => {
    setSelecting(!selecting);
  };

  const handleSelected = (
    id,
    setCurrentTubIndex = false,
    currentTubIndex = 0
  ) => {
    if (selected !== id) {
      initSelected(id);
    }
    if (setCurrentTubIndex) {
      setCurrentTubIndexContext(currentTubIndex);
      setCurrentProduct(
        currentCategory === `subscriptions`
          ? `subscription_${currentTubIndex}`
          : `onetime_${currentTubIndex}`
      );
    }
    setSelecting(!selecting);
  };

  return (
    <S.Wrapper>
      <S.Container
        onClick={handleSelecting}
        className={`${selecting ? `selecting` : ``}`}
        ref={containerRef}
      >
        <S.Title>
          {title}
          <S.Arrow className={`${selecting ? `selecting` : ``}`}></S.Arrow>
        </S.Title>
        <S.Separator />
        <S.SizeContainerWrapper>
          {sizes.map(
            ({
              id,
              icon,
              title,
              description,
              iconWidth = 30,
              iconHeight = 30,
              setCurrentTubIndex = false,
              currentTubIndex = 0
            }) => (
              <S.SizeContainer
                className={`${selecting ? `selecting` : ``} ${
                  selecting || selected === id ? `` : `hidden`
                } ${selecting && selected === id ? `selected` : ``}`}
                onClick={() =>
                  handleSelected(id, setCurrentTubIndex, currentTubIndex)
                }
                key={id}
              >
                <S.Icon>
                  <img
                    src={icon}
                    alt={`${id} size icon`}
                    width={iconWidth}
                    height={iconHeight}
                  />
                </S.Icon>
                <S.Details>
                  <S.DetailTitle dangerouslySetInnerHTML={{ __html: title }} />
                  <S.DetailDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </S.Details>
              </S.SizeContainer>
            )
          )}
        </S.SizeContainerWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default SizeSelector;

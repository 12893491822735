import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 112px;
  position: relative;
`;

export const Container = styled.div`
  border: 1px solid #001c72;
  border-radius: 8px;
  background: #e5f6fa;
  cursor: pointer;
  &:hover,
  &.selecting {
    background: #ccedf4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 60;
  }
`;

export const Title = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  padding: 8px 8px 0;
  position: relative;
`;

export const Arrow = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  &.selecting:before {
    transform: rotate(225deg);
  }
  &:before {
    content: ' ';
    display: block;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    color: #001c72;
    transition: all 500ms ease-in-out 200ms;
    transform: rotate(45deg);
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #99a4c7;
  display: block;
  width: calc(100% - 16px);
  margin: 6.5px auto 0;
`;

export const SizeContainerWrapper = styled.div`
  padding-bottom: 8px;
`;

export const SizeContainer = styled.div`
  margin-top: 8px;
  padding: 0px 8px;
  display: flex;
  gap: 16px;
  transition: all 500ms;
  &.hidden {
    display: none;
  }
  &.selected,
  &.selected:hover {
    background: #e5f6fa;
  }
  &.selecting:hover {
    background: #ffffff4a;
  }
`;

export const Icon = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  & img {
    display: flex;
  }
`;

export const Details = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-size: 16px;
  line-height: 140%;
  gap: 4px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
`;
export const DetailTitle = styled.div`
  & p {
    margin: 0;
  }
`;
export const DetailDescription = styled.div`
  & p {
    margin: 0;
  }
`;
